.label-width{
    width: 150px;
    text-align: left;
    justify-content: flex-start !important;
}
.cs-date-picker .react-datepicker-wrapper{
    flex: 1;
}
.cs-date-picker .react-datepicker__input-container{
    display: inline-flex;
}
.style-check >input{
    margin-top: 0.3rem !important;
    align-self: flex-start !important;
}