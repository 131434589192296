.style-txt-header {
    width: 200px
}
.modal-editor-trainee{
    min-width: 50%;
}
.date-style{
    display: block;
    background-color: #ced4da;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
}