.bg-login {
    /* background-image: url('../../../images/background2.png'); */
    background-color: #f4f6f9;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: grid;
}

.card-login {
    border-radius: 16px !important;
}

.logo-buma {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.font-color-login {
    color: #009D55 !important;
}

.input-group-rounded .input-group-field {
    border-radius: 0 5000px 5000px 0;
    padding-left: 1rem;
}

.input-group-rounded .input-group-icon {
    border-radius: 5000px 0 0 5000px;
    font-size: 0.8rem;
}

.login-btn-cs {
    border-radius: 10px !important;
    background-color: #28a745;
    background-image: linear-gradient(to right, #28a745 , #009D55);
}