#exNVEmpdfQ12_tr {
    animation: exNVEmpdfQ12_tr__tr 3000ms linear infinite reverse forwards
}

@keyframes exNVEmpdfQ12_tr__tr { 
    0% {
        transform: translate(70.140034px,70.713532px) rotate(312.889822deg)
    } 
    100% {
        transform: translate(70.140034px,70.713532px) rotate(35.230599deg)
    }
} 

#exNVEmpdfQ13_tr {
    animation: exNVEmpdfQ13_tr__tr 3000ms linear infinite reverse forwards
}

@keyframes exNVEmpdfQ13_tr__tr { 
    0% {
        transform: translate(124.516737px,103.203635px) rotate(-212.609982deg)
    } 
    100% {
        transform: translate(124.516737px,103.203635px) rotate(-574.986555deg)
    }
} 

#exNVEmpdfQ14_tr {
    animation: exNVEmpdfQ14_tr__tr 3000ms linear infinite reverse forwards
}
@keyframes exNVEmpdfQ14_tr__tr { 
    0% {
        transform: translate(124.891629px,65.719688px) rotate(370.726537deg)
    } 
    100% {
        transform: translate(124.891629px,65.719688px)
        rotate(64.18868deg)
    }
}
