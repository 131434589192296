.tableFixHead {
  overflow-y: auto;
  height: 100px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background: #FAFAFA;
  border-bottom: 2px solid #dee2e6;
  z-index: 1;
}

/* responsive font di dikoment dulu request si bos */
/* @media only screen and (max-width: 768px) {
  .table {
    font-size: 9pt;
  }
  .pagination {
    font-size: 9pt;
  }
  .info-page{
    font-size: 9pt;
  }
  .btn-group{
    font-size: 9pt;
  }
  .dropdown-item{
    font-size: 9pt;
  }
} */

/* Just common table stuff. Really. */
/* table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; } */
th {
  background: #FAFAFA;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.context-menu-animation {
  transition: 3s;
  height: max-content;
  animation: context-in .2s linear;
  /* display: block; */
  overflow: hidden;
}

.hide {
  display: none !important;
}

/* .show {
  transition: 3s;
  display: block;
} */

@keyframes context-in {
  0% {
    height: 0px
  }

  /* 10% {
    height: calc(max-content * 0.1);
  }
  10% {
    height: calc(max-content * 0.1);
  }
  25% {
    height: calc(max-content * 0.25);
  }
  50% {
    height: calc(max-content * 0.5);
  }
  75% {
    height: calc(max-content * 0.75); */
  /* } */
  100% {
    height: 100px;
  }

}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}