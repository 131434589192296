.status-icon {
    background-color: #c40404;
    color: white;
    display: inline-block;
    border-radius: 50rem;
    padding: 2px 15px 2px 15px;
    font-size: 12px;
}

/* draft */
.style-sts-icon-1 {
    background-color: #adb5bd80;
}

/* submitted */
.style-sts-icon-2 {
    background-color: #0474c4;
}

/* approved, passed */
.style-sts-icon-3 {
    background-color: #04b454;
}

/* decline, declined,notavailable, not available*/
.style-sts-icon-4 {
    background-color: #c40404;
}

/* inprogress, in progress, need revise */
.style-sts-icon-5 {
    background-color: #ea7634;
}

/* failed */
.style-sts-icon-6 {
    background-color: black;
}
