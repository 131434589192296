.sidebar{
  background-color: $sidebar !important;
}

.sidebar-dark-primary .nav-sidebar.nav-legacy>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar.nav-legacy>.nav-item>.nav-link.active {
  border-color:$base-color !important;
}

[class*=sidebar-dark] .nav-legacy.nav-sidebar>.nav-item>.nav-link.active {
  color: $base-color !important;
}
[class*=sidebar-dark] .nav-legacy.nav-sidebar>.nav-item>.nav-link{
  color: $sidenav-text !important;
}
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link {
  color: $base-color !important;
}
.brand-link {
  background-color: $brand !important;
}
.brand-text {
  color: $base-color !important;
  font-weight: bold;
}
.main-header{
  background-color: $navbar !important;
}
.bg-base {
  background-color: $base-color !important;
}
.text-base{
  color: $base-color !important;
}
[class*=sidebar-dark-] .nav-header {
  background-color: inherit;
  color: $header-nav !important;
  margin-top: 5px;
  margin-left: 10px;
  opacity: 0.4;
  font-weight: bold;
}
.nav-legacy.nav-sidebar>.nav-item>.nav-link.active {
  background-color: inherit;
  border-left: 5px solid transparent !important;
  box-shadow: none;
}

.card-base:not(.card-outline)>.card-header {
  background-color: $base-color;
  color: white;
}