.upload-area {

    width: 100%;
    margin: auto;
    min-height: 150px;
    border-radius: 25px;
    border: #969696 dashed 1pt;
    padding-top: 25px;
    padding-bottom: 25px;

}

.upload-area > .upload-header {
    text-align: center;
    color: #969696;
    margin: auto;
    font-size: 30pt;
}
.upload-area.hovered {
    border: #08a64a dashed 1pt;
}

.hovered > .upload-header{
    color: #08a64a !important;
}

.label {
    margin-bottom: 20px;
    font-size: 15pt;
    font-weight: bold;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 2rem 1.5rem;
  font: 1rem/1.5 "PT Sans", Arial, sans-serif;
  color: #5a5a5a;
}
